<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="logotypes/currency_36.svg"
            headerText="Setup Your Currency Converter"
            @continue="continueForm"
            @reset="resetForm"
            @input="onFormInput"
            ref="tabbedForm"
            @finish="createCollect"
            :details="details"
            :dirty="dirty"
            :fetched="fetched"
            :update="updateCollect"
            :update-redirect="updateRedirect"
        >
            <template v-slot:step-1-input-mapping="{step}">
                <b-form-group label="Currencies" :label-cols="2" :horizontal="true">
                    <b-row
                        v-for="(mapper, index) in $v.collectForm.currencyMapping.$each.$iter"
                        :key="index"
                        class="mapper-row"
                    >
                        <b-col md="4">
                            <wit-select
                                id="from-currency"
                                placeholder="Currency From"
                                v-model="$v.collectForm.currencyMapping.$each[index].from.$model"
                                :options="currencies"
                            ></wit-select>

                            <Feedback
                                :state="validateRef(`collectForm.currencyMapping.$each.${index}.from`, step)"
                                invalid="Select exchange value"
                                valid="Currency 'from' value is valid"
                            ></Feedback>
                        </b-col>

                        <i class="fa fa-long-arrow-right mapper-arrow"></i>

                        <b-col md="4">
                            <wit-select
                                id="to-currency"
                                placeholder="Currency To"
                                v-model="$v.collectForm.currencyMapping.$each[index].to.$model"
                                :options="currencies"
                            ></wit-select>

                            <Feedback
                                :state="validateRef(`collectForm.currencyMapping.$each.${index}.to`, step)"
                                invalid="Select exchange value"
                                valid="Currency 'to' value is valid"
                            ></Feedback>
                        </b-col>

                        <b-col md="1">
                            <b-button
                                v-if="collectForm.currencyMapping.length > 1"
                                @click="collectForm.currencyMapping.splice(index, 1)"
                                size="sm"
                                variant="danger"
                            >
                                <i class="fa fa-minus"></i>
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="1">
                            <b-button
                                @click="collectForm.currencyMapping.push({from: null, to: null})"
                                size="sm"
                                variant="primary"
                            >
                                <i class="fa fa-plus"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form-group>
            </template>
            <template v-slot:step-1-feedback>
                <b-row>
                    <b-col>
                        <Feedback
                            v-if="feedbackError"
                            :state="feedbackError.state"
                            :invalid="feedbackError.message"
                        ></Feedback>
                    </b-col>
                </b-row>
            </template>
        </wit-tabbed-form>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {collectMixin} from '@/mixins/collectMixin'
import {formMixin} from '@/mixins/formMixin'
import {filterDirty} from '@/shared/filterDirty.js'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        Feedback,
        Loading,
        WitInputGroup,
        WitTabbedForm,
    },
    data() {
        return {
            collectForm: {
                name: '',
                currencyMapping: [
                    {
                        from: null,
                        to: null,
                    },
                ],
            },
            workflowForm: {
                historicalDateRange: 30,
            },
            currentCollectForm: {},
            feedbackError: null,
            currencies: [],
        }
    },
    mixins: [collectMixin, formMixin, validationMixin],
    validations: {
        collectForm: {
            name: {required},
            currencyMapping: {
                $each: {
                    from: {required},
                    to: {required},
                },
            },
        },
        workflowForm: {
            historicalDateRange: {},
        },
    },
    async created() {
        if (!this.details) {
            this.$store.commit('loading/PROCESSING', `Preparing the form...`)

            const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
            const formSessionId = formSessionMap[this.$route.path].id

            this.$gtm.trackEvent({
                event: 'create_resource_start',
                action: 'create',
                value: formSessionId,
            })

            await this.fetchCurrencies()

            this.$store.commit('loading/PROCESSED')
        } else {
            this.$store.commit('loading/PROCESSING', `Fetching...`)
            await Promise.all[(this.fetchCollect(), this.fetchCurrencies())]
            this.$store.commit('loading/PROCESSED')
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            loading: 'loading/state',
        }),
        details() {
            return Boolean(this.$route.params.id)
        },
        cannotContinue() {
            return this.details
        },
        formId() {
            return this.details ? 'currency-converter-create-form' : 'currency-converter-details-form'
        },
        steps() {
            return [
                {
                    name: 'Collect Settings',
                    invalid: this.$v.collectForm.$invalid,
                    inputs: [
                        {
                            name: 'name',
                            value: this.$v.collectForm.name.$model,
                            model: this.$v.collectForm.name,
                            type: 'text',
                            inputLabel: 'Collect name',
                            placeholder: 'Enter your collect name',
                            invalid: 'This field is required',
                            valid: 'Name is valid',
                        },
                        {
                            name: 'mapping',
                        },
                    ],
                },
                this.details
                    ? null
                    : {
                          name: 'Data Collection Settings',
                          invalid: this.$v.$invalid,
                          inputs: [
                              {
                                  name: 'range',
                                  value: this.$v.workflowForm.historicalDateRange.$model,
                                  model: this.$v.workflowForm.historicalDateRange,
                                  type: 'range',
                                  inputLabel: 'Collect data for last (days)',
                                  min: 0,
                                  max: 365,
                              },
                          ],
                      },
            ].filter(Boolean)
        },
        dirty() {
            const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
            return Object.keys(collectFormDirty).length > 0
        },
    },
    methods: {
        async fetchCollect() {
            const {id} = this.$route.params
            const collectResponse = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/collect/currencyConvert/${id}`
            )

            this.collect = collectResponse.data.data

            this.collectForm = {
                name: this.collect.name,
                currencyMapping: this.collect.currencyMapping,
            }
            this.currentCollectForm = cloneDeep(this.collectForm)
            this.fetched = true
        },

        async updateCollect() {
            if (!this.$v.$invalid) {
                if (this.dirty) {
                    const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
                    const data = {
                        ...collectFormDirty,
                    }

                    this.$store.commit('loading/PROCESSING', `Updating...`)
                    try {
                        const {id} = this.$route.params
                        await this.axios.post(
                            `${process.env.VUE_APP_NODE_API_HOST}/collect/currencyConvert/${id}`,
                            data
                        )

                        this.feedbackError = {}
                        this.currentCollectForm = cloneDeep(this.collectForm)
                        this.$v.$reset()

                        this.$store.commit('loading/PROCESSED')
                    } catch (exception) {
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                        this.$errorHandler.report(exception, 'Could not update form')
                    }
                }
            }
        },

        async createCollect(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                this.feedbackError = {
                    state: null,
                    message: '',
                }

                const data = {
                    ...this.collectForm,
                    ...this.workflowForm,
                }

                this.$store.commit('loading/PROCESSING', `Creating your Collect...`)
                try {
                    await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/collect/currencyConvert`, data)
                    this.$store.commit('loading/PROCESSED')
                    this.feedbackError = {}
                    this.resetForm()

                    const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                    const formSessionId = formSessionMap[this.$route.path].id

                    this.$gtm.trackEvent({
                        event: 'create_resource_finish',
                        action: 'finish',
                        value: formSessionId,
                    })

                    formSessionMap[this.$route.path] = null
                    localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                    this.$projectRouter.push(this.redirectUrl)
                } catch (exception) {
                    this.$errorHandler.report(exception, 'Could not create form')
                    this.$store.commit('loading/PROCESSED')
                    this.feedbackError = {
                        state: false,
                        message: exception.response.data.data,
                    }
                }
            }
        },

        async fetchCurrencies() {
            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/collect/currencyConvert/currencyCodes`
            )

            this.currencies = response.data.data
        },
    },
}
</script>

<style lang="scss">
.mapper-row {
    .mapper-arrow {
        line-height: 43px;
        font-size: 1.75 rem;
    }
    .mapper-row-and {
        position: absolute;
        line-height: 43px;
        left: -40px;
        font-weight: 600;
    }
}
</style>
